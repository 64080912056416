import React from 'react';
import { ButtonPrimary, SEO } from '@components';
import { makeStyles } from '@material-ui/core';
import { ERROR_404 } from 'images/GCP/Errors';

const NotFoundPage: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <SEO title='404: Not found' />
      <div className={classes.background}>
        <img src={ERROR_404} alt='404' />
        <div className={classes.text}>
          <p>No encontramos lo que buscabas, pero encontramos este gato.</p>
        </div>
        <ButtonPrimary
          text='Volver al inicio'
          onClick={() => (window.location.href = '/')}
          className={classes.button}
        />
      </div>
    </>
  );
};

export default NotFoundPage;

const useStyles = makeStyles((theme) => ({
  background: {
    overflowX: 'hidden',
    background: theme.palette.primary.main,
    height: '100vh',
    display: 'grid',
    placeItems: 'center',
    padding: '1rem',
  },
  text: {
    color: theme.palette.primary.contrastText,
    fontSize: '2rem',
    textAlign: 'center',
    marginTop: '-14rem',
  },
  button: {
    marginTop: '-25rem',
  },
}));
